@font-face {
  font-family: "2wrap-icons";
  src: url("fonts/2wrap-icons.eot?qj6cx4");
  src: url("fonts/2wrap-icons.eot?qj6cx4#iefix") format("embedded-opentype"),
    url("fonts/2wrap-icons.ttf?qj6cx4") format("truetype"),
    url("fonts/2wrap-icons.woff?qj6cx4") format("woff"),
    url("fonts/2wrap-icons.svg?qj6cx4#2wrap-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wi-"],
[class*=" wi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "2wrap-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wi-accent-wrap:before {
  content: "\e900";
}
.wi-advertising:before {
  content: "\e901";
}
.wi-aeronautics:before {
  content: "\e902";
}
.wi-aeronautics-exterior:before {
  content: "\e903";
}
.wi-aeronautics-interior:before {
  content: "\e904";
}
.wi-architectural:before {
  content: "\e905";
}
.wi-automotive:before {
  content: "\e906";
}
.wi-building-wrap:before {
  content: "\e907";
}
.wi-canvas-frames:before {
  content: "\e908";
}
.wi-carwrap:before {
  content: "\e909";
}
.wi-ceramic-coating:before {
  content: "\e90a";
}
.wi-chrome-delete:before {
  content: "\e90b";
}
.wi-design-wrap:before {
  content: "\e90c";
}
.wi-exhibit-stands:before {
  content: "\e90d";
}
.wi-flags-banners:before {
  content: "\e90e";
}
.wi-fleet-marketing:before {
  content: "\e90f";
}
.wi-furniture:before {
  content: "\e910";
}
.wi-graphic-design:before {
  content: "\e911";
}
.wi-icon-2wrap:before {
  content: "\e912";
}
.wi-logo-2wrap:before {
  content: "\e913";
}
.wi-nautical:before {
  content: "\e914";
}
.wi-other:before {
  content: "\e915";
}
.wi-paint-protection:before {
  content: "\e916";
}
.wi-print-service:before {
  content: "\e917";
}
.wi-signs:before {
  content: "\e918";
}
.wi-smoked-lights:before {
  content: "\e919";
}
.wi-specialties:before {
  content: "\e91a";
}
.wi-striping:before {
  content: "\e91b";
}
.wi-wall-wrap:before {
  content: "\e91c";
}
.wi-wheels:before {
  content: "\e91d";
}
.wi-window-film:before {
  content: "\e91e";
}
.wi-window-graphics:before {
  content: "\e91f";
}
.wi-window-tint:before {
  content: "\e920";
}
.wi-windshield-protection:before {
  content: "\e921";
}
.wi-wrap-colors:before {
  content: "\e922";
}
.wi-yacht-exterior:before {
  content: "\e923";
}
.wi-yacht-interior:before {
  content: "\e924";
}
